@import url("https://fonts.googleapis.com/css2?family=Aclonica&family=Roboto+Slab&family=Indie+Flower&display=swap");
@font-face {
  font-family: "Rune Slasher";
  src: url("http://smaragd.infocafe.org/fonts/RuneSlasher.otf")
    format("opentype");
}
@import-normalize;

html {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: url("/img/bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  /* padding: 10vw 3rem 0 3rem; */
}

#root {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
